

































import Map from '@/components/home/Map.vue';
import {HomepageStore} from '@/store/modules/homepage';
import {MapModel} from '@/shared/interfaces/store/homepage/map.model';
import {Component, Vue} from 'vue-property-decorator';
@Component({
  components: {Map}
})
export default class HomeMap extends Vue {

  get map(): MapModel {
    return HomepageStore.map;
  }

}

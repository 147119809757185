








import {MapModel} from '@/shared/interfaces/store/homepage/map.model';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Map extends Vue {

  @Prop()
  map: MapModel;

  mounted(): void {
    this.map.country.forEach(country => {
      const svg = 'http://www.w3.org/2000/svg';
      const el = document.getElementById(country.code.toUpperCase()) as unknown as SVGPathElement;
      const bbox = el.getBBox();
      const center = {
        x: bbox.x + bbox.width / 2,
        y: bbox.y + bbox.height / 2
      };

      // create the dot
      const dot = document.createElementNS(svg, 'circle');
      dot.setAttribute('cx', `${center.x}`);
      dot.setAttribute('cy', `${center.y}`);
      dot.setAttribute('r', '2');
      dot.setAttribute('fill', '#647ACB');
      dot.setAttribute('class', 'map__dot');
      this.$el.appendChild(dot);
      dot.onmouseenter = (event) => {
        const tooltip = document.createElement('div');
        tooltip.classList.add('_map__tooltip');
        tooltip.innerText = this.$t(country.translate) as string;
        tooltip.style.position = 'absolute';
        tooltip.style.padding = '4px 12px';
        tooltip.style.borderRadius = '8px';
        tooltip.style.backgroundColor = '#323F4B';
        tooltip.style.fontFamily = 'Jost';
        tooltip.style.fontSize = '16px';
        tooltip.style.lineHeight = '24px';
        tooltip.style.color = '#F5F7FA';
        tooltip.style.fontWeight = '3000';
        if (this.$el.parentElement) {
          this.$el.parentElement.appendChild(tooltip);
        }
        tooltip.style.left = `${event.pageX - (tooltip.getBoundingClientRect().width / 2)}px`;
        tooltip.style.top = `${event.pageY - 64 - tooltip.getBoundingClientRect().height - 12}px`;
      };
      dot.onmouseleave = () => {
        const el = document.querySelector('._map__tooltip');
        if (el) {
          el.remove();
        }
      };
    });
  }

}
